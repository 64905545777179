import { z } from 'zod';

const PaymentMethodsArray = ['Moyasar', 'Tamara', 'Tabby'] as const;

const OrderStatusSchema = z.enum([
  'PENDING',
  'COMPLETE',
  'AWAITING_PAYMENT',
  'FAILED',
  'REFUND_REQUESTED',
  'REFUNDED',
  'REFUND_REJECTED',
  'PARTIALLY_REFUNDED',
  'PARTIAL_REFUND_REQUESTED',
  // 'PARTIAL_REFUND_REJECTED', // Uncomment if needed
  'CANCELED',
  'PARTIALLY_PAID',
  'APPROVED',
  'AUTHORISED',
  'DECLINED',
  'EXPIRED',
] as const);

const TabbyStatusArray = ['success', 'cancel', 'failure'] as const;
const MoyasarStatusArray = [
  'initiated',
  'paid',
  'failed',
  'authorized',
  'captured',
  'refunded',
  'voided',
  'verified',
] as const;

export const TabbyPaymentStatusSchema = z.enum(TabbyStatusArray);
export const MoyasarPaymentStatusSchema = z.enum(MoyasarStatusArray);

export const PaymentMethodSchema = z.enum(PaymentMethodsArray);
export type PaymentMethod = z.infer<typeof PaymentMethodSchema>;

export const PaymentQueryResponseSchema = z.discriminatedUnion(
  'payment-method',
  [
    z.object({
      'payment-method': z
        .literal(PaymentMethodSchema.Enum.Moyasar.toLowerCase())
        .transform(() => PaymentMethodSchema.Enum.Moyasar),
      'payment-status': OrderStatusSchema,
      'order-id': z.coerce.number(),
      message: z.string().optional(),
    }),
    z.object({
      'payment-method': z
        .literal(PaymentMethodSchema.Enum.Tabby.toLowerCase())
        .transform(() => PaymentMethodSchema.Enum.Tabby),
      'payment-status': TabbyPaymentStatusSchema,
      payment_id: z.string(),
      'order-id': z.coerce.number(),
    }),
  ]
);

export type PaymentQueryResponse = z.infer<typeof PaymentQueryResponseSchema>;

export const PaymentRedirectSchema = z.object({
  id: z.string(),
  status: MoyasarPaymentStatusSchema,
  'payment-method': z.literal('moyasar'),
  message: z.string(),
  'order-id': z.coerce.number(),
});

export type PaymentRedirectData = z.infer<typeof PaymentRedirectSchema>;
