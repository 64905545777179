import { useShowToast } from '@/composables/useShowToast.composable';
import { PurchasedItemTypeEnum } from '@/helpers/enums/financial-system.enum';
import { handleErrorMessageFromServer } from '@/helpers/functions/handleErrorMessageFromServer';
import type { CourseWithUserData } from '@/helpers/interfaces/course.interface';
import type { SingleOrder } from '@/helpers/interfaces/order.interface';
import type { SocialEventWithUserData } from '@/helpers/interfaces/social.interface';
import { axiosI, useI18 } from '@/plugins';
import { financialSystemApiHandler } from '@/postman-to-ts/apiHandler/financialSystem';
import { lMSApiHandler } from '@/postman-to-ts/apiHandler/lMS';
import { socialApiHandler } from '@/postman-to-ts/social';
import type { AxiosError } from 'axios';
import { defineStore } from 'pinia';
import { ref, type Ref } from 'vue';
import { useOrganizationStore } from './organzation.pinia';
import { useAuthStore } from './auth.store';
import type { MoyasarData } from '@/helpers/interfaces/cart-moyasar.interface';
import { MoyasarSchema } from '../helpers/interfaces/cart-moyasar.interface';
import { type PaymentRedirectData } from '@/helpers/interfaces/financial.interface';

interface ICartStore {
  cart: Ref<SingleOrder | null>;
  moyasarData: Ref<MoyasarData | null>;
  isLoading: Ref<boolean>;
  isLoadingTamara: Ref<boolean>;
  isLoadingTabby: Ref<boolean>;
  initializeCartStore: Function;
  removeItemFromCart: (purchasedItemId: string | number) => Promise<void>;
  // @TODO: Function type should never be used

  checkout: Function;
  checkoutAtTamara: Function;
  checkoutAtTabby: Function;
  generateMoyasarCheckout: () => Promise<
    | MoyasarData
    | {
        message: any;
        status: number;
      }
  >;
  addToCart: ({
    data,
    type,
  }: {
    data: CourseWithUserData | SocialEventWithUserData;
    type: PurchasedItemTypeEnum;
  }) => Promise<boolean | undefined>;
  itemId: Ref<number>;
  applyVoucher: Function;
  removeVoucher: Function;
  verifyPaymentOnRedirect: (
    redirectData: PaymentRedirectData
  ) => Promise<{ redirectTo: string }>;
}

export const useCartStore = defineStore('CartStore', (): ICartStore => {
  /**
   >>>>>>>>>>>>>>> state variables >>>>>>>>>>>>>>>
   */
  const {
    t,
    i18next: { language },
  } = useI18();

  const cart = ref<SingleOrder | null>(null);
  const moyasarData = ref<MoyasarData | null>(null);
  const isLoading = ref(false);
  const isLoadingTamara = ref(false);
  const isLoadingTabby = ref(false);
  const itemId = ref(0);
  const authStore = useAuthStore();

  //======================================================================
  //>>>>>>>>>>>>>>> Methods
  //======================================================================
  const initializeCartStore = async () => {
    if (!authStore.user?.id) return;
    const { path, method } = financialSystemApiHandler.users.getCart;
    isLoading.value = true;
    isLoadingTamara.value = true;
    isLoadingTabby.value = true;
    try {
      const { data, status } = await axiosI<{
        errors: any[];
        order: SingleOrder;
      }>(path(), {
        method,
      });
      isLoading.value = false;
      isLoadingTamara.value = false;
      isLoadingTabby.value = false;
      if (status == 200 || status == 201) {
        cart.value = data.order;
      }
    } catch (e) {
      console.log('ERROR IN-->initializeCart()', e);
      return e;
    } finally {
      isLoading.value = false;
    }
  };

  const removeItemFromCart = async (purchasedItemId: string | number) => {
    if (!cart.value?.purchasedItems?.length) return;
    const indexOfItemToRemove = cart.value?.purchasedItems.findIndex(
      (item) => item.id == purchasedItemId
    );

    itemId.value =
      (cart.value.purchasedItems[indexOfItemToRemove].itemId as number) || 0;
    const cmsKey =
      'cart.' +
      cart.value.purchasedItems[
        indexOfItemToRemove
      ].purchasedItemType.slug.toLowerCase() +
      'Removed';

    const { path, method } = financialSystemApiHandler.users.removeItemFromCart;
    await axiosI<{
      errors: any[];
      order: SingleOrder;
    }>(
      path({
        purchasedItemId,
      }),
      {
        method,
      }
    ).catch((e: AxiosError) => {
      return handleErrorMessageFromServer(e);
    });
    itemId.value = 0;
    useShowToast.success({
      msg: t(cmsKey, { courseText: useOrganizationStore().courseText }),
    });
    cart.value.purchasedItems.splice(indexOfItemToRemove, 1);
    initializeCartStore();
  };

  const checkout = async () => {
    isLoading.value = true;
    const { path, method } = financialSystemApiHandler.users.checkout;
    try {
      const res = await axiosI<{
        url: string;
        order: SingleOrder;
      }>(path(), {
        method,
      });
      isLoading.value = false;
      setTimeout(async () => {
        await initializeCartStore();
      }, 3000);
      return { data: res, status: res.status };
    } catch (e: any) {
      isLoading.value = false;
      return handleErrorMessageFromServer(e);
    }
  };

  const generateMoyasarCheckout = async () => {
    isLoading.value = true;

    const { path, method } =
      financialSystemApiHandler.users.generateMoyasarCheckout;
    try {
      const res = await axiosI<Omit<MoyasarData, 'language'>>(path(), {
        method,
      });
      setTimeout(async () => {
        await initializeCartStore();
      }, 3000);

      moyasarData.value = MoyasarSchema.parse({ ...res.data, language });
      return moyasarData.value;
    } catch (e: any) {
      console.log({ e });
      return handleErrorMessageFromServer(e);
    } finally {
      isLoading.value = false;
    }
  };

  const checkoutAtTamara = async () => {
    isLoadingTamara.value = true;
    const { path, method } = financialSystemApiHandler.users.checkoutTamara;
    try {
      const res = await axiosI<{
        url: string;
        order: SingleOrder;
      }>(path(), {
        method,
        headers: {
          language: language,
        },
      });
      isLoadingTamara.value = false;
      setTimeout(async () => {
        await initializeCartStore();
      }, 3000);
      return { data: res, status: res.status };
    } catch (e: any) {
      isLoadingTamara.value = false;
      return handleErrorMessageFromServer(e);
    }
  };

  const checkoutAtTabby = async () => {
    isLoadingTabby.value = true;
    const { path, method } = financialSystemApiHandler.users.checkoutTabby;
    try {
      const res = await axiosI<{
        url: string;
        order: SingleOrder;
      }>(path(), {
        method,
        headers: {
          language: language,
        },
      });
      isLoadingTabby.value = false;
      setTimeout(async () => {
        await initializeCartStore();
      }, 3000);
      return { data: res, status: res.status };
    } catch (e: any) {
      isLoadingTabby.value = false;
      return handleErrorMessageFromServer(e);
    }
  };

  const addToCart = async ({
    type,
    data,
  }: {
    data: CourseWithUserData | SocialEventWithUserData;
    type: PurchasedItemTypeEnum;
  }) => {
    let path: string = '';
    let method: string = '';
    itemId.value = data.id!;
    let cmsKey = '';
    let courseText = '';

    switch (type) {
      case PurchasedItemTypeEnum.COURSE:
        path = lMSApiHandler.courses.enrollment.enrollInCourse.path({
          courseId: data.id!,
        });
        method = lMSApiHandler.courses.enrollment.enrollInCourse.method;
        cmsKey = useOrganizationStore().marketplaceEnabled
          ? 'cart.courseAddedSuccessfully'
          : 'cart.coursePurchasedSuccessfully';
        courseText = useOrganizationStore().courseText;
        break;

      case PurchasedItemTypeEnum.EXTERNALCOURSE:
        path = lMSApiHandler.externalCourses.student.enroll.path({
          externalCourseId: data.id!,
        });
        method = lMSApiHandler.externalCourses.student.enroll.method;
        cmsKey = useOrganizationStore().marketplaceEnabled
          ? 'cart.courseAddedSuccessfully'
          : 'cart.coursePurchasedSuccessfully';
        courseText = useOrganizationStore().courseText;
        break;

      case PurchasedItemTypeEnum.EVENT:
        path = socialApiHandler.events.enrollment.enrollInEvent.path({
          eventId: data.id!,
        });
        method = socialApiHandler.events.enrollment.enrollInEvent.method;
        cmsKey = useOrganizationStore().marketplaceEnabled
          ? 'cart.eventAddedSuccessfully'
          : 'cart.eventPurchasedSuccessfully';
        break;

      case PurchasedItemTypeEnum.SPECIALIZATION:
        path = lMSApiHandler.specializations.enrollInSpecialization.path({
          specializationId: data.id!,
        });
        method = lMSApiHandler.specializations.enrollInSpecialization.method;
        cmsKey = useOrganizationStore().marketplaceEnabled
          ? 'cart.specializationAddedSuccessfully'
          : 'cart.specializationPurchasedSuccessfully';
        break;
    }
    isLoading.value = true;
    isLoadingTamara.value = true;
    isLoadingTabby.value = true;
    try {
      const result = await axiosI<SingleOrder>(path, {
        method,
      });
      if (result.status == 200 || result.status == 201) {
        cart.value = result.data;
        if (!useOrganizationStore().marketplaceEnabled) await checkout();
        useShowToast.success({ msg: t(cmsKey, { courseText }) });
        return true;
      }
    } catch (e) {
      handleErrorMessageFromServer(e as AxiosError);
      return false;
    } finally {
      isLoading.value = false;
      isLoadingTamara.value = false;
      isLoadingTabby.value = false;
      itemId.value = 0;
    }
  };

  const applyVoucher = async (voucherCode: string) => {
    const { path, method } = financialSystemApiHandler.vouchers.applyVoucher;
    try {
      const { data, status } = await axiosI<{
        errors: any[];
        order: SingleOrder;
      }>(path(), {
        method,
        data: {
          voucherCode,
        },
      });
      if (status == 200 || status == 201) {
        cart.value = data.order;
      }
    } catch (e) {
      console.log('ERROR IN-->applyCoupon()', e);
      return e;
    }
  };

  const removeVoucher = async () => {
    const { path, method } = financialSystemApiHandler.vouchers.removeVoucher;
    try {
      const { data, status } = await axiosI<{
        errors: any[];
        order: SingleOrder;
      }>(path(), {
        method,
      });
      if (status == 200 || status == 201) {
        cart.value = data.order;
      }
    } catch (e) {
      console.log('ERROR IN-->removeVoucher()', e);
      return e;
    }
  };

  const verifyPaymentOnRedirect = async (
    routeData: PaymentRedirectData
  ): Promise<{ redirectTo: string }> => {
    const { path, method } =
      financialSystemApiHandler.users.verifyMoyasarPayment;
    const { data } = await axiosI(path(), {
      method,
      data: routeData,
    });

    return data;
  };
  return {
    cart,
    isLoading,
    isLoadingTamara,
    isLoadingTabby,
    itemId,
    initializeCartStore,
    addToCart,
    removeItemFromCart,
    checkout,
    checkoutAtTamara,
    checkoutAtTabby,
    applyVoucher,
    removeVoucher,
    generateMoyasarCheckout,
    moyasarData,
    verifyPaymentOnRedirect,
  };
});
