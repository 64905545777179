import { z } from 'zod';

export const MoyasarSchema = z.object({
  amount: z.number(),
  currency: z.string().default('SAR'),
  publishable_api_key: z.string(),
  callback_url: z.string(),
  methods: z.array(z.string()),
  description: z.string(),
  language: z.union([z.literal('ar'), z.literal('en')]),
});

export type MoyasarData = z.infer<typeof MoyasarSchema>;
