export enum AuthErrorCodesEnum {
  UserIsDisabled = 'auth-001',
  UserNotInOrganization = 'auth-002',
  InvalidCredentials = 'auth-003',
  TooManyRequests = 'auth-004',
  UserShouldResetPassword = 'auth-005',
  InvalidAccessToken = 'auth-006',
  InvalidRefreshToken = 'auth-007',
  Invalid2FAToken = 'auth-008',
  RequiresApproval = 'auth-009',
  NotVerified = 'auth-010',
  PhoneNumberInUse = 'auth-011',
  PhoneNumberNotValid = 'auth-012',
}
