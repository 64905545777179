import { EnumRoutes } from '@/helpers/enums/routes.enum';
import type { RouteRecordRaw } from 'vue-router';

export const financialRoutes: Array<RouteRecordRaw> = [
  {
    path: EnumRoutes.financials.cartPage.path,
    name: EnumRoutes.financials.cartPage.name,
    component: () => import('@/views/financials/CartPage.vue'),
    meta: EnumRoutes.financials.cartPage.meta,
  },
  //order history page
  {
    path: EnumRoutes.financials.paymentHistory.path,
    name: EnumRoutes.financials.paymentHistory.name,
    component: () => import('@/views/financials/PaymentHistory.vue'),
    meta: EnumRoutes.financials.paymentHistory.meta,
  },
  // single order page
  {
    path: EnumRoutes.financials.singleOrder.path,
    name: EnumRoutes.financials.singleOrder.name,
    component: () => import('@/views/financials/SingleOrder.vue'),
    meta: EnumRoutes.financials.singleOrder.meta,
  },
  {
    path: EnumRoutes.financials.paymentCompleteRedirect.path,
    name: EnumRoutes.financials.paymentCompleteRedirect.name,
    component: () => import('@/views/financials/CompletePaymentRedirect.vue'),
    meta: EnumRoutes.financials.paymentCompleteRedirect.meta,
  },
];
