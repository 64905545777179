import router from '@/router';
import { defineStore } from 'pinia';

import { EnumRoutes } from '@/helpers/enums/routes.enum';
import type {
  AuthError,
  AuthSecrets,
  IPortals,
} from '@/helpers/interfaces/auth.interface';
import { axiosI } from '@/plugins';
import { authApiHandler } from '@/postman-to-ts/apiHandler/auth';
import type { RegisterDto } from '@/submodules/dtos/register.dto';
import type { User } from '@/submodules/generated_types/types/user';
import { ref, type Ref } from 'vue';

import useJWTTokenComposable from '@/composables/useJWTToken.composable';
import { EnumOrganizationRegistrationStrategy } from '@/helpers/enums/organization-config.enum';
import type { ProfileSubmit } from '@/helpers/interfaces/profile.submit.interface';
import type { UserWithExtraFields } from '@/helpers/interfaces/user.inteface';
import { miscApiHandler } from '@/postman-to-ts/apiHandler/misc';
import { socialApiHandler } from '@/postman-to-ts/social';
import { useQueryClient } from '@tanstack/vue-query';
import { AxiosError } from 'axios';
import { useCartStore } from './cart.store';
import { useGroupStore } from './groups.store';
import { useNotificationsStore } from './notifications.store';
import { useOrganizationStore } from './organzation.pinia';
import { usePermissionsStore } from './permissoins.store';

interface IAuthStore {
  user: Ref<UserWithExtraFields | User | null>;
  authError: Ref<AuthError | null>;
  initializeAuth: () => Promise<void>;
  register: (payload: {
    registerDto: RegisterDto;
    clientOrganizationId: number;
  }) => Promise<{
    data?: UserWithExtraFields;
    status?: number;
    error?: AxiosError<{
      message?: string;
      statusCode?: number;
      errorCode?: string;
    }>;
  }>;
  login: (body: { email: string; password: string }) => Promise<any>;
  twoFactorAuthentication: (
    params: typeof authApiHandler.login.twoFactorAuthentication.params
  ) => Promise<any>;
  verifyToken: (isResetPassword?: boolean) => Promise<any>;
  resendCode: (
    params: typeof authApiHandler.login.resendCode.params
  ) => Promise<any>;
  refreshAccessToken: (isInitializeAuth?: boolean) => Promise<any>;
  requestSSO: (params?: { [key: string]: string | number }) => Promise<any>;
  resetPassword: (body: { password: string; token: string }) => Promise<any>;
  requestResetPassword: (body: { email: string }) => Promise<any>;
  logout: (removeAll?: boolean) => Promise<any>;
  previousRoute: Ref<string | null>;
  setPreviousRoute: (previous: string | null) => void;
  editUser: (updateUser: ProfileSubmit) => Promise<any>;
  changePassword: (body: {
    oldPassword: string;
    newPassword: string;
  }) => Promise<any>;
  shouldLogged: Ref<boolean>;
  shouldHavePermission: Ref<boolean>;
  handleLogout: () => Promise<any>;
  loginSSO: (superToken: string, onlyView: boolean) => Promise<any>;
  handleSAMLSSO: (ssoid: string) => Promise<any>;
  instructorView: Ref<boolean>;
  twoFactorTokenExpiresAt: Ref<string | undefined>;
  shouldResetPassword: Ref<boolean>;
  portals: Ref<IPortals | null>;
  verifyEmail: (token: string) => Promise<{
    data?: any;
    status?: number;
    error?: AxiosError<{ errorCode?: string }>;
  }>;
}

export const useAuthStore = defineStore('AuthStore', (): IAuthStore => {
  /**
  >>>>>>>>>>>>>>> state variables >>>>>>>>>>>>>>>
  */

  const authError = ref<AuthError | null>(null);
  const user = ref<UserWithExtraFields | null>(null);
  const secrets = ref<AuthSecrets | null>(null);
  const previousRoute = ref<string | null>(null);
  const shouldLogged = ref<boolean>(false);
  const shouldHavePermission = ref<boolean>(false);
  const instructorView = ref<boolean>(false);
  const twoFactorTokenExpiresAt = ref<string | undefined>(undefined);
  const shouldResetPassword = ref(false);
  const queryClient = useQueryClient();
  // this is temporary until we recieved it in the auth API
  const portals: Ref<IPortals | null> = ref(null);
  let timeoutHandle: ReturnType<typeof setTimeout>;

  //________________ Check if there is a token and set the user
  async function initializeAuth() {
    setPreviousRoute(localStorage.getItem('previousRoute'));
    const userFromStorage = localStorage.getItem('user');

    user.value = userFromStorage ? JSON.parse(userFromStorage) : null;
    portals.value = JSON.parse(localStorage.getItem('portals') || 'null');
    const access = localStorage.getItem('access');
    const refresh = localStorage.getItem('refresh');

    twoFactorTokenExpiresAt.value =
      localStorage.getItem('twoFactorTokenExpiresAt') || undefined;
    instructorView.value =
      sessionStorage.getItem('instructorView') === 'true' ? true : false;
    usePermissionsStore().setPermissions();
    if (access && refresh) {
      handleExpiredAccessToken(true);
      handleVariousFunctionsFromOtherStores();
      //________________ Group ________________
      useGroupStore().getUserGroup();
    } else if (!access) {
      handleLogout(false);
    } else {
      logout();
    }
  }
  async function handleExpiredAccessToken(isInitializeAuth = false) {
    const token = useJWTTokenComposable();
    timeoutHandle = setTimeout(handleExpiredAccessToken, 15000);
    if (!token) return;
    const baseurl = import.meta.env.VITE_APP_API_BASE_URL.split(
      '//'
    )[1].replace('/api/', '');
    if (!baseurl.includes('services'))
      console.log(token.exp - Date.now() / 1000);

    if (token.exp - Date.now() / 1000 > 30) {
      // not expired
      if (isInitializeAuth) {
        refreshAccessToken(isInitializeAuth);
        await verifyToken();
      }
    } else {
      // expired
      if (isInitializeAuth) handleLogout();
      else await refreshAccessToken();
    }
  }
  /**
>>>>>>>>>>>>>>> functions >>>>>>>>>>>>>>>
*/
  async function handleLogout(changeRoute: boolean = true) {
    clearTimeout(timeoutHandle);
    secrets.value = null;
    user.value = null;
    authError.value = null;
    portals.value = null;
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('user');
    localStorage.removeItem('portals');
    useCartStore().cart = null;
    closeSse();
    if (changeRoute) {
      // to prevent calling permissions store twice when load the app

      // get the permissions on auth change
      await usePermissionsStore().initializePermissions();
      // check the permission for the current route
      const currentRoute = router.currentRoute.value;
      const havePermission = usePermissionsStore().checkAuthentication(
        currentRoute.meta
      );
      if (havePermission) return;
      else await router.replace({ name: EnumRoutes.landing.root.name });
    }
  }
  function handleVariousFunctionsFromOtherStores() {
    //________________ Query Client ________________
    queryClient.clear();
    //________________ Cart ________________
    useCartStore().initializeCartStore();
  }
  let eventSource: EventSource | null = null;

  const closeSse = () => {
    if (eventSource) {
      eventSource.close();
      eventSource = null;
    }
  };

  const subscribeToServerSSe = async () => {
    if (!user.value) return;

    const { path, params } = miscApiHandler.sSE.subscribeToSSE;
    const baseURL = import.meta.env.VITE_APP_API_BASE_URL;

    const info: typeof params = {
      token: localStorage.getItem('access') ?? '',
    };
    const url = baseURL + path() + `?id=${user.value?.id}&token=${info.token}`;
    try {
      useNotificationsStore().getNotifications();
      eventSource = new EventSource(url);
      eventSource.onerror = () => {};
      eventSource.onmessage = (event) => {
        enum SseEventTypes {
          notification = 'notification',
        }
        //>>>>>>> once lina decides the format of the messages, and we have more message types than just notifications, will utilize the switch case below. leaving it here for now to just fetch notifications.
        const data = event?.data?.split(':')?.[0];

        useNotificationsStore().getNotifications();
        switch (data as SseEventTypes) {
          case SseEventTypes.notification:
            break;
          default:
            break;
        }
      };
    } catch (error: any) {
      console.log(
        '🚀 ~ file: auth.store.ts:180 ~ subscribeToServerSSe ~ error:',
        error
      );

      return error;
    }
  };

  async function handleNewAccessToken(
    data: AuthSecrets,
    isInitializeAuth = false
  ) {
    if (data.expiresAt) {
      localStorage.setItem('expiresAt', data.expiresAt);
    }
    if (data.refresh) {
      localStorage.setItem('refresh', data.refresh);
    }
    if (data.access) {
      localStorage.setItem('access', data.access);
      await Promise.all([verifyToken()]);
    }
    if (data.portals) {
      localStorage.setItem('portals', JSON.stringify(data.portals));
      portals.value = data.portals;
    }
    //________________ Notifications ________________
    closeSse();
    subscribeToServerSSe();
    if (isInitializeAuth) return;
    handleVariousFunctionsFromOtherStores();
    // get the permissions on auth change
    await usePermissionsStore().initializePermissions();
    //________________ Group ________________
    useGroupStore().getUserGroup();
  }

  /**
  >>>>>>>>>>>>>>> APIs >>>>>>>>>>>>>>>
  */
  //________________ Register ________________
  async function register(payload: {
    registerDto: RegisterDto;
    clientOrganizationId: number;
  }) {
    const { path, method } = authApiHandler.register.register;
    try {
      const { data, status } = await axiosI<UserWithExtraFields>(path(), {
        method,
        data: payload.registerDto,
        headers: {
          Organization: payload.clientOrganizationId,
        },
      });

      if (
        status &&
        status >= 200 &&
        status < 400 &&
        (useOrganizationStore().registrationRequiresApproval?.strategy ===
          EnumOrganizationRegistrationStrategy.OFF ||
          !useOrganizationStore().registrationRequiresApproval?.strategy)
      ) {
        await login({
          email: payload.registerDto.email,
          password: payload.registerDto.password,
        });
      }
      return { data, status };
    } catch (error) {
      return {
        error: error as AxiosError<{
          statusCode?: number;
          message?: string;
        }>,
      };
    }
  }

  //________________ login ________________
  async function login(body: { email: string; password: string }) {
    const { path, method } = authApiHandler.login.login;

    try {
      const { data, status } = await axiosI<AuthSecrets>(path(), {
        method,
        data: body,
      });
      secrets.value = data || null;
      twoFactorTokenExpiresAt.value = secrets.value?.expiresAt;
      if (twoFactorTokenExpiresAt.value)
        localStorage.setItem(
          'twoFactorTokenExpiresAt',
          twoFactorTokenExpiresAt.value
        );
      if (secrets.value?.twoFactorToken)
        await router.replace({
          name: EnumRoutes.auth.twoFactorVerification.name,
          query: { token: secrets.value?.twoFactorToken },
        });
      else {
        await handleSetLoginData(data);
      }
      handleExpiredAccessToken();
      return { data, status };
    } catch (error: any) {
      console.log('🚀 ~ file: auth.pinia.ts:62 ~ login ~ error:', error);
      // handleErrorMessageFromServer(error,'explanation');
      authError.value = error.response.data;
      return error;
    }
  }

  //________________ twoFactorAuthentication ________________
  async function twoFactorAuthentication(
    params: typeof authApiHandler.login.twoFactorAuthentication.params
  ): Promise<any> {
    const { path, method } = authApiHandler.login.twoFactorAuthentication;
    try {
      const { data, status } = await axiosI<AuthSecrets>(path(), {
        method,
        params,
      });
      await handleSetLoginData(data);
      return { data, status };
    } catch (error: any) {
      return error;
    }
  }

  const handleSetLoginData = async (data: AuthSecrets) => {
    await handleNewAccessToken(data);
    sessionStorage.removeItem('instructorView');
    const havePermission = usePermissionsStore().checkAuthentication(
      EnumRoutes.lms.allCourses.meta
    );
    await handleStoredSsoid();
    previousRoute.value
      ? await router.push(previousRoute.value)
      : await router.push(
          havePermission
            ? {
                name: EnumRoutes.lms.allCourses.name,
              }
            : { name: EnumRoutes.landing.root.name }
        );
  };

  //handle if ssoid token is present
  const handleStoredSsoid = async () => {
    const ssoid = localStorage.getItem('ssoid');
    if (ssoid) {
      localStorage.removeItem('ssoid');
      return await handleSAMLSSO(ssoid);
    }
    return;
  };

  //________________ verifyToken ________________

  async function verifyToken(isResetPassword?: boolean) {
    const { path, method } = authApiHandler.verifyToken;
    try {
      if (isResetPassword) return;
      const { data, status } = await axiosI<UserWithExtraFields>(path(), {
        method,
      });
      user.value = data;
      shouldResetPassword.value = user.value.forceResetPassword;
      localStorage.setItem('user', JSON.stringify(data));
      const { path: path2, method: method2 } =
        authApiHandler.sSO.generateSSOToken;
      axiosI(path2(), {
        method: method2,
        params: { portal: 'LMS' },
      });
      return { data, status };
    } catch (error: any) {
      return error;
    }
  }

  //________________ resendCode ________________
  async function resendCode(
    params: typeof authApiHandler.login.resendCode.params
  ) {
    const { path, method } = authApiHandler.login.resendCode;
    try {
      const { data, status } = await axiosI<{
        message: string;
        expiresAt: string;
      }>(path(), {
        method,
        params,
      });
      twoFactorTokenExpiresAt.value = data.expiresAt;
      if (twoFactorTokenExpiresAt.value)
        localStorage.setItem(
          'twoFactorTokenExpiresAt',
          twoFactorTokenExpiresAt.value
        );
      return { data, status };
    } catch (error: any) {
      return error;
    }
  }

  //________________ refresh access token ________________
  async function refreshAccessToken(isInitializeAuth = false) {
    const refreshToken = localStorage.getItem('refresh');
    const { path, method } = authApiHandler.refresh_New;
    try {
      const { data, status } = await axiosI<AuthSecrets>(path(), {
        method,
        data: {
          token: refreshToken,
        },
      });
      await handleNewAccessToken(data, isInitializeAuth);
      return { data, status };
    } catch (error: any) {
      return error;
    }
  }
  // handle SAML SSO
  async function handleSAMLSSO(ssoid: string) {
    const { path, method } = authApiHandler.sAML.authenticateUser;
    try {
      const { data, status } = await axiosI(path({ ssoid }), {
        method,
      });
      // return { data, status };
      //the data is of type HTML, I need to render it in the browser
      const parser = new DOMParser();
      const html = parser.parseFromString(data, 'text/html');
      const form = html.querySelector('form');
      if (form) {
        document.body.appendChild(form);
        form.submit();
      }
      return { data, status };
    } catch (error: any) {
      return error;
    }
  }

  //________________ SSO ________________
  async function loginSSO(superToken: string, onlyView: boolean) {
    const { path, method } = authApiHandler.sSO.getTokensUsingSSO;
    try {
      const { data, status } = await axiosI<AuthSecrets>(path(), {
        method,
        params: {
          token: superToken,
        },
        headers: {
          Authorization: `Bearer ${superToken}`,
        },
      });

      await handleNewAccessToken(data);
      handleExpiredAccessToken();
      return { data, status };
    } catch (error: any) {
      sessionStorage.removeItem('instructorView');
      instructorView.value = false;
      return error;
    }
  }
  async function requestSSO(params?: { [key: string]: string | number }) {
    try {
      const { path, method } = authApiHandler.sSO.generateSSOToken;
      const { data } = await axiosI(path(), {
        method,
        params,
      });
      return data;
    } catch (error) {
      return error;
    }
  }

  //________________ Reset password ________________

  async function resetPassword(body: { password: string; token: string }) {
    const { path, method } = authApiHandler.resetPassword.resetPassword;
    try {
      const { status } = await axiosI(path(), {
        method,
        data: body,
      });
      return status;
    } catch (error: any) {
      return error.response.status;
    }
  }

  //________________ requestResetPassword  ________________
  async function requestResetPassword(body: { email: string }) {
    const { path, method } =
      authApiHandler.resetPassword.requestResetPasswordStudent;
    try {
      const { data, status } = await axiosI(path(), {
        method,
        data: body,
      });
      return { data, status };
    } catch (error: any) {
      return error;
    }
  }

  //________________ logout ________________

  async function logout(removeAll?: boolean) {
    const { path, method } = authApiHandler.logout;
    try {
      const { data, status } = await axiosI(path(), {
        method,
        data: {
          removeAll,
        },
      });
      await handleLogout();
      return { data, status };
    } catch (error: any) {
      return error;
    }
  }

  //________________ set previous route ________________
  // set the previous route to redirect to it after login
  function setPreviousRoute(previous: string | null) {
    previous
      ? localStorage.setItem('previousRoute', previous)
      : localStorage.removeItem('previousRoute');
    previousRoute.value = previous;
  }

  // ____________________ Edit User ____________________
  async function editUser(updateUser: ProfileSubmit) {
    const { path, method } = socialApiHandler.users.updateUser;
    const { data, status } = await axiosI(path(), {
      method,
      data: updateUser,
    });
    await verifyToken();
    return { data, status };
  }

  async function changePassword(body: {
    oldPassword: string;
    newPassword: string;
  }) {
    const { path, method } = socialApiHandler.users.changePassword;
    try {
      const data = await axiosI(path(), {
        method,
        data: body,
      });
      if (shouldResetPassword.value)
        await verifyToken().catch(async (error) => {
          if (error.response.data.errorCode == 401) {
            await refreshAccessToken().catch(() => {
              handleLogout();
            });
          }
        });
      return data;
    } catch (error: any) {
      return error;
    }
  }
  async function verifyEmail(token: string) {
    const { path, method } = authApiHandler.emailVerification.verifyEmail;
    try {
      const { data, status } = await axiosI(path(), {
        method,
        params: {
          token,
        },
      });
      return { data, status };
    } catch (error) {
      return { error: error as AxiosError<{ errorCode?: string }> };
    }
  }

  return {
    user,
    authError,
    initializeAuth,
    register,
    login,
    twoFactorAuthentication,
    verifyToken,
    resendCode,
    refreshAccessToken,
    resetPassword,
    requestResetPassword,
    logout,
    previousRoute,
    setPreviousRoute,
    editUser,
    changePassword,
    shouldLogged,
    shouldHavePermission,
    handleLogout,
    loginSSO,
    handleSAMLSSO,
    requestSSO,
    instructorView,
    twoFactorTokenExpiresAt,
    shouldResetPassword,
    portals,
    verifyEmail,
  };
});
